import React from "react";
import $ from "jquery";

import ProjectForm from "../forms/ProjectForm";

export default class Projects extends React.Component {
  componentDidMount() {
    $(".trigger_sharer").click(function (event) {
      $(".sharer-wrapper").toggleClass("active");
      $(".trigger_sharer").toggleClass("active");
      $("section").toggleClass("shift-right");
      $("header").toggleClass("minimise-header");
      $(".scroll").toggleClass("hide");

      event.preventDefault();
    });
    $("#close-sharer").click(function (event) {
      $(".sharer-wrapper").toggleClass("active");
      $(".trigger_sharer").toggleClass("active");
      $("section").toggleClass("shift-right");
      $("header").toggleClass("minimise-header");
      $(".scroll").toggleClass("hide");
      event.preventDefault();
    });
  }

  render() {
    return (
      <React.Fragment>
        <div className="sharer">
          <a className="trigger_sharer" href="/" rel="noopener noreferrer">
            <span className="hide-on-mobile">Start Project</span>
            <span className="hide show-on-mobile">Start</span>
          </a>
        </div>
        <div className="sharer-wrapper">
          <div className="sharer-description">
            <div>
              <div className="align-left">
                <a id="close-sharer" href="/" rel="noopener noreferrer">
                  <i className="far fa-times-circle"></i>
                </a>
              </div>
              <ProjectForm />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
