import React from "react";

import Sphere from "../widgets/Sphere";

export default class StaticBackground extends React.Component {
  render() {
    return (
      <section className="fixed">
        <div className="sphere-bg">
          <Sphere />
        </div>
      </section>
    );
  }
}
