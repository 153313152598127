import React from "react";
import emailjs from "emailjs-com";

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

export default class ProjectForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      message: "",
      error: "",
      submitted: false,
    };
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleMessageChange = this.handleMessageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();

    if (
      this.state.name.trim() === "" ||
      this.state.email.trim() === "" ||
      this.state.message.trim() === ""
    ) {
      this.setState({ error: "Please ensure that all fields are filled in" });
      return;
    }
    emailjs
      .sendForm(
        "gmail",
        "template_0q43xgc",
        event.target,
        "user_xxspsVfPRvHG6OZ7AB4W0"
      )
      .then(
        (result) => {
          //console.log(result.text);
          this.setState({
            submitted: true,
            error: "",
          });
        },
        (error) => {
          console.log(error.text);
          this.setState({ error: "An error has occured" });
        }
      );

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "new-project", ...this.state }),
    });

    event.target.reset();
  }

  handleNameChange(event) {
    this.setState({ name: event.target.value });
  }
  handleEmailChange(event) {
    this.setState({ email: event.target.value });
  }
  handleMessageChange(event) {
    this.setState({ message: event.target.value });
  }

  render() {
    return (
      <div className="project-form">
        {this.state.submitted ? (
          <React.Fragment>
            <h2 className="success-message">
              Thanks!{" "}
              <span className="red-font">Let's make something special</span>
            </h2>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <h2>Got an upcoming project?</h2>
            <h2 className="secondary">Lets get crackin</h2>
            <div className="w100-right">
              <div className="form-line"></div>
            </div>
            <form name="contact" onSubmit={this.handleSubmit}>
              <label>
                Name
                <input
                  type="text"
                  name="name"
                  placeholder="Name"
                  value={this.state.name}
                  onChange={this.handleNameChange}
                />
              </label>
              <label>
                Email
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={this.state.email}
                  onChange={this.handleEmailChange}
                />
              </label>
              <label>
                Message
                <textarea
                  name="message"
                  rows="3"
                  placeholder="Message"
                  value={this.state.message}
                  onChange={this.handleMessageChange}
                />
              </label>
              <div className="error-message">{this.state.error}</div>
              <button type="submit" className="margin-top-20">
                Submit
              </button>
            </form>
          </React.Fragment>
        )}
      </div>
    );
  }
}
