import $ from "jquery";
import { TimelineLite } from "gsap";

let animatedSlide0,
  animatedSlide1,
  animatedSlide2,
  animatedSlide3,
  animatedSlide4 = false;

export function animateSlide(slideNo, preloaderComplete) {
  switch (slideNo) {
    case 0: {
      if (!animatedSlide0 && preloaderComplete) {
        animatedSlide0 = true;

        let borderTimeline = new TimelineLite();

        let borderRight = $("[gsap='border-right'");
        let borderLeft = $("[gsap='border-left'");
        let borderUp = $("[gsap='border-top'");
        let borderDown = $("[gsap='border-bottom'");
        let headerName = $("[gsap='header-name'");
        let headerActions = $("[gsap='header-actions'");
        let navigation = $("[gsap='navigation'");
        let bannerName = $("[gsap='banner-name'");
        let bannerScroll = $("[gsap='banner-scroll'");
        let bannerContact = $("[gsap='banner-contact'");
        let bannerBadgeOne = $("[gsap='banner-badge-one'");
        let bannerBadgeTwo = $("[gsap='banner-badge-two'");

        borderTimeline
          .to(borderUp, { y: 0, duration: 0.5, ease: "power3" }, "-1<")
          .to(borderDown, { y: 0, duration: 0.5, ease: "power3" }, "<")
          .to(borderRight, { x: 0, duration: 0.5, ease: "power3" }, "<")
          .to(borderLeft, { x: 0, duration: 0.5, ease: "power3" }, "<")
          .to(
            bannerName,
            {
              opacity: 1,
              duration: 0.5,
              ease: "power3",
            },
            "1"
          )
          .to(headerActions, { opacity: 1, duration: 0.4, ease: "power3" }, "<")
          .to(bannerContact, { opacity: 1, duration: 0.4, ease: "power3" }, "<")
          .to(navigation, { opacity: 1, duration: 0.4, ease: "power3" }, "<")
          .to(
            [bannerBadgeOne, bannerBadgeTwo],
            { opacity: 1, duration: 0.4, ease: "power3" },
            "<"
          )
          .to(
            headerName,
            { opacity: 1, delay: -0.7, duration: 0.4, ease: "power3" },
            "<"
          )
          .to(bannerScroll, { opacity: 1, duration: 0.4, ease: "power3" }, "<");
      }
      break;
    }
    case 1: {
      //Banner
      if (!animatedSlide1) {
        animatedSlide1 = true;

        let backgroundTimeline = new TimelineLite();

        let backgroundTitleOne = $("[gsap='bg-title-one']");
        let backgroundTitleTwo = $("[gsap='bg-title-two']");
        let backgroundTitleThree = $("[gsap='bg-title-three']");
        let backgroundContent = $("[gsap='bg-content']");
        let backgroundBadges = $("[gsap='bg-badges']");

        backgroundTimeline
          .to([backgroundTitleOne, backgroundTitleTwo, backgroundTitleThree], {
            y: 0,
            duration: 0.8,
            delay: 0.5,
            ease: "power3",
            stagger: 0.15,
          })
          .to(
            backgroundContent,
            {
              y: 0,
              opacity: 1,
              duration: 0.6,
              ease: "power3",
            },
            "-=0.4"
          )
          .to(
            backgroundBadges,
            { y: 0, opacity: 1, duration: 0.6, ease: "power3" },
            "<"
          );
      }
      break;
    }
    case 2: {
      //Background
      if (!animatedSlide2) {
        animatedSlide2 = true;

        let outsystemsTimeline = new TimelineLite();

        let outsystemsBgText = $("[gsap='os-bg-text']");

        outsystemsTimeline.to(outsystemsBgText, {
          opacity: 0.15,
          duration: 1,
          delay: 1,
          ease: "power3",
          stagger: 0.15,
        });
      }
      break;
    }
    case 3: {
      //Articles
      if (!animatedSlide3) {
        animatedSlide3 = true;

        let articlesTimeline = new TimelineLite();

        let articlesTitleOne = $("[gsap='articles-title']");
        let articlesContent = $("[gsap='articles-content']");
        let articleCards = $("[gsap='article-card']");

        articlesTimeline
          .to(articlesTitleOne, {
            y: 0,
            duration: 0.5,
            delay: 0.8,
            ease: "power3",
          })
          .to(articlesContent, {
            y: 0,
            opacity: 1,
            duration: 0.5,
            ease: "power3",
          })
          .to(articleCards, {
            opacity: 1,
            ease: "power3",
          });
      }

      break;
    }
    case 4: {
      //Contact
      if (!animatedSlide4) {
        animatedSlide4 = true;

        let contactTimeline = new TimelineLite();

        let contactTextOne = $("[gsap='contact-text-one']");
        let contactTextTwo = $("[gsap='contact-text-two']");
        let contactEmail = $("[gsap='contact-email']");
        let contactSocial = $("[gsap='contact-social']");
        let contactScroll = $("[gsap='contact-scroll']");

        contactTimeline
          .to(contactTextOne, {
            opacity: 1,
            y: 0,
            duration: 1,
            delay: 1.2,
            ease: "power3",
          })
          .to(
            contactTextTwo,
            {
              opacity: 1,
              duration: 0.8,
              ease: "power3",
            },
            ">"
          )
          .to([contactEmail, contactSocial, contactScroll], {
            opacity: 1,
            duration: 0.4,
            ease: "power3",
          });
      }

      break;
    }
    default:
  }
}

function animateProject(projectSectionName) {
  let project = new TimelineLite();

  let projectTitleFirst = $(
    "#" + projectSectionName + " [gsap='project-title-first']"
  );
  let projectTitleSecond = $(
    "#" + projectSectionName + " [gsap='project-title-second']"
  );
  let projectDevices = $(
    "#" + projectSectionName + " [gsap='project-devices']"
  );
  let projectDescription = $(
    "#" + projectSectionName + " [gsap='project-description']"
  );
  let projectTech = $("#" + projectSectionName + " [gsap='project-tech']");
  let projectLink = $("#" + projectSectionName + " [gsap='project-link']");
  let projectImageOne = $(
    "#" + projectSectionName + " [gsap='project-image-one']"
  );
  let projectImageTwo = $(
    "#" + projectSectionName + " [gsap='project-image-two']"
  );
  let projectImageThree = $(
    "#" + projectSectionName + " [gsap='project-image-three']"
  );

  project
    .to([projectTitleFirst, projectTitleSecond], {
      y: 0,
      duration: 0.8,
      delay: 0.5,
      ease: "power3",
      stagger: 0.15,
    })
    .to([projectDevices, projectDescription, projectTech, projectLink], {
      opacity: 1,
      duration: 0.8,
      ease: "power3",
      stagger: 0.15,
    })
    .to(
      [projectImageOne, projectImageTwo, projectImageThree],
      {
        opacity: 1,
        y: 0,
        duration: 0.8,
        ease: "power3",
        stagger: 0.2,
      },
      "-=1"
    );
}
