import React from "react";

import OutSystemsLogo from "../../assets/outsystems/outSystems-nologo-white.webp";
import OutSystemsLogo_PNG from "../../assets/outsystems/outSystems-nologo-white.png";
import OutSystemsLogoDark from "../../assets/outsystems/outSystems-nologo-dark.webp";
import OutSystemsLogoDark_PNG from "../../assets/outsystems/outSystems-nologo-dark.png";
import OutSystemsRedBadge from "../../assets/outsystems/icon-badge.webp";
import OutSystemsRedBadge_PNG from "../../assets/outsystems/icon-badge.png";
import OutSystemsYellowBadge from "../../assets/outsystems/icon-badge-yellow.webp";
import OutSystemsYellowBadge_PNG from "../../assets/outsystems/icon-badge-yellow.png";
import OutSystemsAmbassadorBadge from "../../assets/outsystems/icon-ambassador-badge-2.webp";
import OutSystemsAmbassadorBadge_PNG from "../../assets/outsystems/icon-ambassador-badge-2.png";
import OutSystemsTrainerBadge from "../../assets/outsystems/icon-trainer-badge.webp";
import OutSystemsTrainerBadge_PNG from "../../assets/outsystems/icon-trainer-badge.png";

export default class OutSystemsBadge extends React.Component {
  ShowRedBadge(props) {
    if (this.props.RedBadge) {
      return (
        <picture>
          <source type="image/webp" srcSet={OutSystemsRedBadge} />
          <source type="image/png" srcSet={OutSystemsRedBadge_PNG} />
          <img
            src={OutSystemsRedBadge_PNG}
            className="badge-icon"
            alt={this.props.title}
          />
        </picture>
      );
    }
  }

  ShowYellowBadge(props) {
    if (this.props.YellowBadge) {
      return (
        <picture>
          <source type="image/webp" srcSet={OutSystemsYellowBadge} />
          <source type="image/png" srcSet={OutSystemsYellowBadge_PNG} />
          <img
            src={OutSystemsYellowBadge_PNG}
            className="badge-icon"
            alt={this.props.title}
          />
        </picture>
      );
    }
  }

  ShowAmbassadorBadge(props) {
    if (this.props.AmbassadorBadge) {
      return (
        <picture>
          <source type="image/webp" srcSet={OutSystemsAmbassadorBadge} />
          <source type="image/png" srcSet={OutSystemsAmbassadorBadge_PNG} />
          <img
            src={OutSystemsAmbassadorBadge_PNG}
            className="badge-icon"
            alt={this.props.title}
          />
        </picture>
      );
    }
  }

  ShowTrainerBadge(props) {
    if (this.props.TrainerBadge) {
      return (
        <picture>
          <source type="image/webp" srcSet={OutSystemsTrainerBadge} />
          <source type="image/png" srcSet={OutSystemsTrainerBadge_PNG} />
          <img
            src={OutSystemsTrainerBadge_PNG}
            className="badge-icon"
            alt={this.props.title}
          />
        </picture>
      );
    }
  }

  AddToggleClass(props) {
    if (this.props.toggleName) {
      return "toggle";
    }
    return "";
  }
  componentDidMount() {}

  render() {
    return (
      <React.Fragment>
        <div
          className={
            "gsap-hidden vertical-align badge-item" +
            (this.props.dark ? " dark" : "")
          }
          gsap={this.props.gsap}
        >
          {this.ShowRedBadge()}
          {this.ShowYellowBadge()}
          {this.ShowAmbassadorBadge()}
          {this.ShowTrainerBadge()}
          <div className={"badge-name " + this.AddToggleClass()}>
            <div className="badge-os-logo">
              {this.props.dark ? (
                <picture>
                  <source type="image/webp" srcSet={OutSystemsLogoDark} />
                  <source type="image/png" srcSet={OutSystemsLogoDark_PNG} />
                  <img
                    src={OutSystemsLogoDark_PNG}
                    className="logo"
                    alt={this.props.title}
                  />
                </picture>
              ) : (
                <picture>
                  <source type="image/webp" srcSet={OutSystemsLogo} />
                  <source type="image/png" srcSet={OutSystemsLogo_PNG} />
                  <img
                    src={OutSystemsLogo_PNG}
                    className="logo"
                    alt={this.props.title}
                  />
                </picture>
              )}
            </div>
            {this.props.title}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
