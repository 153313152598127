import React from "react";

export default class Navigation extends React.Component {
  render() {
    return (
      <nav className="vetical-scrollbar gsap-hidden" gsap="navigation">
        <ul id="navigation">
          <li data-menuanchor="banner" className="active">
            <span>1</span>
            <a id="nav_banner" href="#banner"></a>
          </li>
          <li data-menuanchor="background">
            <span>2</span>
            <a id="nav_background" href="#background"></a>
          </li>
          <li data-menuanchor="outsystems">
            <span>3</span>
            <a href="#outsystems"></a>
          </li>
          <li data-menuanchor="articles">
            <span>4</span>
            <a href="#articles"></a>
          </li>
          <li data-menuanchor="contact">
            <span>5</span>
            <a href="#contact"></a>
          </li>
        </ul>
      </nav>
    );
  }
}
