import React from "react";
import "@fortawesome/fontawesome-free/js/all.js";

import StaticBackground from "./js/layout/StaticBackground";
import Header from "./js/layout/Header";
import Navigation from "./js/layout/Navigation";
import ScrollIcon from "./js/widgets/ScrollIcon";
import { Home } from "./js/pages/Home";

import AwwwardsBanner from "./js/widgets/AwwwardsBanner";

import "./App.scss";

function App() {
  return (
    <div className="App">
      <div className="borders">
        <div className="top" gsap="border-top"></div>
        <div className="bottom" gsap="border-bottom"></div>
        <div className="left" gsap="border-left"></div>
        <div className="right" gsap="border-right"></div>
      </div>
      {/*<AwwwardsBanner />*/}
      <Header />
      <Navigation />
      <StaticBackground />
      <Home />
      <ScrollIcon />
    </div>
  );
}

export default App;
