import React from "react";
import Section from "../layout/Section";

import OutSystemsBadge from "../widgets/OutSystemsBadge";

export default function Background(props) {
  return (
    <Section name="background">
      <div className="background-wrapper">
        <h1 className="margin-bottom-50">
          <div>
            <div className="gsap-reveal" gsap="bg-title-one">
              Hey,
            </div>
          </div>
          <div>
            <div className="gsap-reveal" gsap="bg-title-two">
              Hows it
            </div>
          </div>
          <div>
            <div className="gsap-reveal" gsap="bg-title-three">
              goin?
            </div>
          </div>
        </h1>

        <div className="content gsap-hidden gsap-reveal" gsap="bg-content">
          My name is Ossama Ghanem and I'm a certified OutSystems{" "}
          <span className="strong-font">Tech Lead</span>,{" "}
          <span className="strong-font">Trainer</span> and{" "}
          <span className="strong-font">Ambassador</span> based in Sydney,{" "}
          Australia.<br></br>
        </div>

        <div className="content wide">
          <div
            className="outsystems-badge-wrapper block-view mobile-layout gsap-hidden gsap-reveal"
            gsap="bg-badges"
          >
            <div className="badge-group">
              <OutSystemsBadge title="Trainer" TrainerBadge dark />
              <OutSystemsBadge title="Ambassador" AmbassadorBadge dark />
            </div>
            <div className="badge-group">
              <OutSystemsBadge title="Technical Lead" RedBadge dark />
              <OutSystemsBadge title="Reactive Dev" RedBadge dark />
              <OutSystemsBadge title="Traditional Dev" RedBadge dark />
            </div>
            <div className="badge-group">
              <OutSystemsBadge title="Mobile Specialist" YellowBadge dark />
              {/*<OutSystemsBadge title="Arch. Specialist" YellowBadge dark />
              <OutSystemsBadge title="Front End Specialist" YellowBadge dark />*/}
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
}
