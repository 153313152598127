import React from "react";

import scrollDown from "../../assets/icons/scroll.icon.png";
import scrollUp from "../../assets/icons/scroll.icon-reverse.png";

export default class ScrollIcon extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="scroll scroll-up">
          <a href="#scroll" onClick={() => window.fullpage_api.moveTo(1)}>
            <img src={scrollUp} alt="scroll-icon" />
          </a>
        </div>
        <div className="scroll scroll-down gsap-hidden" gsap="banner-scroll">
          <a href="#scroll" onClick={() => window.fullpage_api.moveSectionDown()}>
            <img src={scrollDown} alt="scroll-icon" />
          </a>
        </div>
      </React.Fragment>
    );
  }
}
