import React from "react";

import GraphQLIcon from "../assets/icons/tech/dark/graphql.webp";
import GraphQLIcon_PNG from "../assets/icons/tech/dark/graphql.png";
import SQLIcon from "../assets/icons/tech/dark/sql.webp";
import SQLIcon_PNG from "../assets/icons/tech/dark/sql.png";
import WixIcon from "../assets/icons/tech/dark/wix.webp";
import WixIcon_PNG from "../assets/icons/tech/dark/wix.png";
import ReactIcon from "../assets/icons/tech/dark/react.webp";
import ReactIcon_PNG from "../assets/icons/tech/dark/react.png";
import NodeIcon from "../assets/icons/tech/dark/nodeJs.webp";
import NodeIcon_PNG from "../assets/icons/tech/dark/nodeJs.png";
import SassIcon from "../assets/icons/tech/dark/sass.webp";
import SassIcon_PNG from "../assets/icons/tech/dark/sass.png";
import NetlifyIcon from "../assets/icons/tech/dark/netlify.webp";
import NetlifyIcon_PNG from "../assets/icons/tech/dark/netlify.png";

export default class ProjectTechnologies extends React.Component {
  ShowNodeIcon(props) {
    if (this.props.ShowNodeIcon) {
      return (
        <li>
          <picture>
            <source type="image/webp" srcSet={NodeIcon} />
            <source type="image/png" srcSet={NodeIcon_PNG} />
            <img src={NodeIcon_PNG} alt="nodejs" />
          </picture>
          <span>NodeJS</span>
        </li>
      );
    }
  }
  ShowReactIcon(props) {
    if (this.props.ShowReactIcon) {
      return (
        <li>
          <picture>
            <source type="image/webp" srcSet={ReactIcon} />
            <source type="image/png" srcSet={ReactIcon_PNG} />
            <img src={ReactIcon_PNG} alt="react" />
          </picture>
          <span>ReactJS</span>
        </li>
      );
    }
  }
  ShowGraphQLIcon(props) {
    if (this.props.ShowGraphQLIcon) {
      return (
        <li>
          <picture>
            <source type="image/webp" srcSet={GraphQLIcon} />
            <source type="image/png" srcSet={GraphQLIcon_PNG} />
            <img src={GraphQLIcon_PNG} alt="graphql" />
          </picture>
          <span>GraphQL</span>
        </li>
      );
    }
  }
  ShowSQLIcon(props) {
    if (this.props.ShowSQLIcon) {
      return (
        <li>
          <picture>
            <source type="image/webp" srcSet={SQLIcon} />
            <source type="image/png" srcSet={SQLIcon_PNG} />
            <img src={SQLIcon_PNG} alt="sql" />
          </picture>
          <span>SQL</span>
        </li>
      );
    }
  }
  ShowWixIcon(props) {
    if (this.props.ShowWixIcon) {
      return (
        <li>
          <picture>
            <source type="image/webp" srcSet={WixIcon} />
            <source type="image/png" srcSet={WixIcon_PNG} />
            <img src={WixIcon_PNG} alt="wix" />
          </picture>
          <span>Wix</span>
        </li>
      );
    }
  }
  ShowSassIcon(props) {
    if (this.props.ShowSassIcon) {
      return (
        <li>
          <picture>
            <source type="image/webp" srcSet={SassIcon} />
            <source type="image/png" srcSet={SassIcon_PNG} />
            <img src={SassIcon_PNG} alt="sass" />
          </picture>
          <span>Sass</span>
        </li>
      );
    }
  }
  ShowNetlifyIcon(props) {
    if (this.props.ShowNetlifyIcon) {
      return (
        <li>
          <picture>
            <source type="image/webp" srcSet={NetlifyIcon} />
            <source type="image/png" srcSet={NetlifyIcon_PNG} />
            <img src={NetlifyIcon_PNG} alt="Netlify" />
          </picture>
          <span>Netlify</span>
        </li>
      );
    }
  }

  render() {
    return (
      <React.Fragment>
        <ul className="project-icons">
          {this.ShowNodeIcon()}
          {this.ShowReactIcon()}
          {this.ShowGraphQLIcon()}
          {this.ShowWixIcon()}
          {this.ShowSQLIcon()}
          {this.ShowSassIcon()}
          {this.ShowNetlifyIcon()}
        </ul>
      </React.Fragment>
    );
  }
}
