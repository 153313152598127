import React from "react";

import ProjectTechnologies from "./ProjectTechnologies";

export default class ProjectItem extends React.Component {
  ShowLink(props) {
    if (this.props.link) {
      return (
        <a
          target="_blank"
          className="button secondary"
          href={this.props.link}
          data-rel="external"
          rel="noopener noreferrer">
          visit
        </a>
      );
    }
  }

  render() {
    return (
      <div className="project-wrapper">
        <div className="content layout-left">
          <div className="project-content">
            <div className="project-name">
              <div className="hide-overflow">
              <div className="gsap-reveal" gsap="project-title-first">
              {this.props.titleFirst}
              </div>
              </div>
              <div className="hide-overflow">
              <div className="gsap-reveal" gsap="project-title-second">
              {this.props.titleSecond}
              </div>
              </div>
            </div>
            <div className="project-devices gsap-hidden" gsap="project-devices">
              <div>mobile • tablet • desktop</div>
              <span className="project-number">{this.props.year}</span>
            </div>

            <div className="project-description gsap-hidden" gsap="project-description">
              <p>{this.props.description}</p>
            </div>
            <div className="project-technologies gsap-hidden" gsap="project-tech">
              <ProjectTechnologies
                ShowNodeIcon={this.props.ShowNodeIcon}
                ShowReactIcon={this.props.ShowReactIcon}
                ShowSassIcon={this.props.ShowSassIcon}
                ShowNetlifyIcon={this.props.ShowNetlifyIcon}
                ShowGraphQLIcon={this.props.ShowGraphQLIcon}
                ShowSQLIcon={this.props.ShowSQLIcon}
                ShowWixIcon={this.props.ShowWixIcon}
              />
            </div>

            <div className="project-link gsap-hidden" gsap="project-link">{this.ShowLink()}</div>
          </div>
        </div>
        <div className="content layout-right">
          <div className="layout-floating-card slide-effect">
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}
