import React from "react";

import Section from "../layout/Section";

import SocialMedia from "../widgets/SocialMedia";
import OutSystemsBadge from "../widgets/OutSystemsBadge";

export default function Banner() {
  return (
    <Section name="banner">
      <div className="name">
        <div
          gsap="banner-contact"
          className="banner-contact-wrapper gsap-hidden"
        >
          <SocialMedia />
        </div>
        <div gsap="banner-name" className="gsap-hidden">
          <div>
            <p>Ossama</p>
          </div>
          <div>
            <p>Ghanem</p>
          </div>
        </div>

        <div className="outsystems-badge-wrapper">
          <OutSystemsBadge
            title="Trainer"
            TrainerBadge
            gsap="banner-badge-one"
          ></OutSystemsBadge>
          <OutSystemsBadge
            title="Technical Lead"
            RedBadge
            gsap="banner-badge-two"
          ></OutSystemsBadge>
        </div>
      </div>
      <div className="mobile-banner-bg"></div>
    </Section>
  );
}
