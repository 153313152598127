import React from "react";

export default class OutSystemsLogo extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.0"
        width="100%"
        height="100%"
        viewBox="0 0 1000.000000 1000.000000"
        preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,1000.000000) scale(0.100000,-0.100000)">
          <path d="M4600 9939 c-861 -74 -1642 -347 -2355 -822 -448 -299 -870 -699 -1207 -1143 -456 -602 -776 -1333 -912 -2084 -56 -308 -76 -544 -76 -895 0 -351 20 -587 76 -895 223 -1231 909 -2332 1926 -3091 740 -552 1612 -878 2563 -959 155 -13 625 -13 780 0 1060 90 1996 474 2810 1154 146 121 470 444 584 581 689 831 1071 1758 1161 2820 5 66 10 242 10 390 0 455 -41 795 -146 1216 -181 727 -520 1385 -1025 1994 -116 139 -440 462 -585 582 -829 686 -1748 1063 -2809 1153 -139 11 -656 11 -795 -1z m578 -3164 c265 -15 594 -129 838 -291 475 -317 779 -840 813 -1402 44 -737 -346 -1414 -1007 -1746 -807 -407 -1799 -140 -2307 619 -561 838 -333 1968 509 2529 184 122 425 222 646 266 106 22 301 39 375 34 22 -2 82 -6 133 -9z" />
        </g>
      </svg>
    );
  }
}
