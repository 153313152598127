import React from "react";
import Section from "../layout/Section";

import ProjectItem from "../ProjectItem";

import SCA_Home_Mobile from "../../assets/projects/sca/mobile-sca.webp";
import SCA_Home_Mobile_PNG from "../../assets/projects/sca/mobile-sca.png";
import SCA_About_Mobile from "../../assets/projects/sca/mobile-sca-about.webp";
import SCA_About_Mobile_PNG from "../../assets/projects/sca/mobile-sca-about.png";
import SCA_Contact_Mobile from "../../assets/projects/sca/mobile-sca-contact.webp";
import SCA_Contact_Mobile_PNG from "../../assets/projects/sca/mobile-sca-contact.png";

export default class Project_SCA extends React.Component {
  render() {
    return (
      <Section name="sca">
        <ProjectItem
          titleFirst="The"
          titleSecond="SCA."
          description="The Sustainable Community Association works to promote
                  livelihoods and create a sustainable society through
                  development projects based on partnership with the community."
          year="2020"
          link="https://sca-lb.org"
          mainColor="#1a1a1a"
          secondaryColor="#434343"
          ShowReactIcon
          ShowNodeIcon
          ShowSassIcon
          ShowNetlifyIcon
          ShowMobileIcon
          ShowTabletIcon
          ShowDesktopIcon>
          <picture>
            <source type="image/webp" srcSet={SCA_Home_Mobile} />
            <source type="image/png" srcSet={SCA_Home_Mobile_PNG} />
            <img
              className="project-frame gsap-hidden gsap-reveal" gsap="project-image-one"
              src={SCA_Home_Mobile_PNG}
              alt="sca-main"
            />
          </picture>
          <picture>
            <source type="image/webp" srcSet={SCA_About_Mobile} />
            <source type="image/png" srcSet={SCA_About_Mobile_PNG} />
            <img
              className="project-frame gsap-hidden gsap-reveal" gsap="project-image-two"
              src={SCA_About_Mobile_PNG}
              alt="sca-about"
            />
          </picture>
          <picture>
            <source type="image/webp" srcSet={SCA_Contact_Mobile} />
            <source type="image/png" srcSet={SCA_Contact_Mobile_PNG} />
            <img
              className="project-frame gsap-hidden gsap-reveal" gsap="project-image-three"
              src={SCA_Contact_Mobile_PNG}
              alt="sca-contact"
            />
          </picture>
        </ProjectItem>
      </Section>
    );
  }
}
