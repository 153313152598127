import React from "react";
import Section from "../layout/Section";

import SocialMedia from "../widgets/SocialMedia";

import scrollUpWhite from "../../assets/icons/scroll.icon-reverse-white.png";

export default class ContactMe extends React.Component {
  render() {
    return (
      <Section name="contact">
        <h1 className="bg-text">Hire me</h1>

        <div className="contact-text">
          <h1 className="gsap-reveal gsap-hidden" gsap="contact-text-one">
            Minding a project?
          </h1>
          <h1 className="secondary gsap-hidden" gsap="contact-text-two">
            Let's talk.
          </h1>
        </div>
        <div
          className="center-align bring-front gsap-hidden"
          gsap="contact-email">
          <a
            className="link"
            target="_blank"
            href="mailto:portfolio@ossamaghanem.com"
            data-rel="external"
            rel="noopener noreferrer">
            portfolio@ossamaghanem.com
          </a>
        </div>
        <div
          className="center-align bring-front gsap-hidden"
          gsap="contact-social">
          <SocialMedia />
        </div>
        <div className="scroll-back-up hide show-on-mobile">
          <div className="gsap-hidden" gsap="contact-scroll">
            <a href="#scroll" onClick={() => window.fullpage_api.moveTo(1)}>
              <img src={scrollUpWhite} alt="scroll-icon" />
            </a>
          </div>
        </div>
      </Section>
    );
  }
}
