import React from "react";

import LinkedIn_PNG from "../../assets/contact/linkedin-white.png";
import OutSystems_PNG from "../../assets/outsystems/outSystems-logo-white.png";

export default class ArticleCard extends React.Component {
  ShowOutSystemsIcon(props) {
    if (this.props.OutSystemsIcon) {
      return (
        <div className="article-outsystems-container">
          <picture>
            <img src={OutSystems_PNG} alt="outsystems" />
          </picture>
        </div>
      );
    }
  }

  ShowHashtagOne(props) {
    if (this.props.hashtagOne != null) {
      return <div className="article-hashtag">#{this.props.hashtagOne}</div>;
    }
  }
  ShowHashtagTwo(props) {
    if (this.props.hashtagTwo != null) {
      return <div className="article-hashtag">#{this.props.hashtagTwo}</div>;
    }
  }
  ShowHashtagThree(props) {
    if (this.props.hashtagThree != null) {
      return <div className="article-hashtag">#{this.props.hashtagThree}</div>;
    }
  }

  render() {
    var articleImageStyle = {
      backgroundImage: `url(${this.props.imageURL})`,
    };

    return (
      <a
        target="_blank"
        href={this.props.url}
        data-rel="external"
        rel="noopener noreferrer"
      >
        <div className="article-card">
          <div className="article-image" style={articleImageStyle}>
            <div className="article-image-overlay"></div>
            {this.ShowOutSystemsIcon()}
            <div className="article-source-container">
              <picture>
                <img src={LinkedIn_PNG} alt="linkedin" />
              </picture>
            </div>
            <div className="article-hashtag-container">
              {this.ShowHashtagOne()}
              {this.ShowHashtagTwo()}
              {this.ShowHashtagThree()}
            </div>
          </div>
          <div className="article-text">
            <div className="article-year">{this.props.year}</div>

            {this.props.children}
          </div>
        </div>
      </a>
    );
  }
}
