import React from "react";

import OutSystemsLogo from "../svg/OutSystemsLogo";

export default class SocialMedia extends React.Component {
  render() {
    return (
      <div className="contact-links">
        <ul>
          <li>
            <a
              target="_blank"
              href="mailto:portfolio@ossamaghanem.com"
              data-rel="external"
              rel="noopener noreferrer">
              <i className="far fa-envelope"></i>
            </a>
          </li>
          <li>
            <a
              target="_blank"
              href="https://www.linkedin.com/in/ossamaghanem/"
              rel="noopener noreferrer">
              <i className="fab fa-linkedin-in"></i>
            </a>
          </li>
          <li>
            <a
              target="_blank"
              href="https://www.outsystems.com/profile/uiakvvzwds/"
              rel="noopener noreferrer">
              <OutSystemsLogo />
            </a>
          </li>
          <li>
            <a
              target="_blank"
              href="https://github.com/OssamaGh"
              rel="noopener noreferrer">
              <i className="fab fa-github"></i>
            </a>
          </li>
          {/* <li>
            <a
              target="_blank"
              href="https://www.instagram.com/ossamaghanem/"
              rel="noopener noreferrer">
              <i class="fab fa-instagram"></i>
            </a>
          </li> */}
        </ul>
      </div>
    );
  }
}
