import React from "react";
import ReactFullpage from "@fullpage/react-fullpage";
import env from "react-dotenv";

import Banner from "../sections/Banner";
import Background from "../sections/Background";
import OutSystems from "../sections/OutSystems";
import Articles from "../sections/Articles";
import ProjectPortfolio from "../sections/Project_Portfolio";
import ProjectSCA from "../sections/Project_SCA";
import ContactMe from "../sections/ContactMe";

import { animateSlide } from "../animations";

export const Home = () => (
  <ReactFullpage
    //fullpage options
    licenseKey={env.FULLPAGE_LICENSE_KEY}
    scrollingSpeed={1000}
    easingcss3={"cubic-bezier(0.645, 0.045, 0.355, 1)"}
    menu={"#navigation"}
    resize={true}
    onLeave={onLeave.bind(this)}
    afterResize={onResize.bind(this)}
    afterLoad={afterLoad.bind(this)}
    render={({ state, fullpageApi }) => {
      return (
        <ReactFullpage.Wrapper>
          <Banner />
          <Background />
          <OutSystems />
          <Articles />
          <ContactMe />
        </ReactFullpage.Wrapper>
      );
    }}
  />
);

function afterLoad(origin, destination, direction) {}

function onLeave(origin, destination, direction) {
  animateSlide(destination.index, false);
}

function onResize(width, height) {
  window.fullpage_api.reBuild();
}
