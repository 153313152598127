import React from "react";
import Section from "../layout/Section";

export default class OutSystems extends React.Component {
  render() {
    return (
      <Section name="outsystems">
        <div className="os-banner-wrapper">
          <div className="os-logo-outer-circle">
            <div className="os-logo-inner-circle"></div>
          </div>
        </div>
        <div className="bg-phrase-wrapper">
          <span className="bg-phrase gsap-hidden" gsap="os-bg-text">
            Financial
          </span>
          <span className="bg-phrase gsap-hidden" gsap="os-bg-text">
            Government
          </span>
          <span className="bg-phrase gsap-hidden" gsap="os-bg-text">
            Real Estate
          </span>
          <span className="bg-phrase gsap-hidden" gsap="os-bg-text">
            Compliance
          </span>
          <span className="bg-phrase gsap-hidden" gsap="os-bg-text">
            Security
          </span>
          <span className="bg-phrase gsap-hidden" gsap="os-bg-text">
            Utilities
          </span>
        </div>
        <div className="background-wrapper">
          <div className="content">
            My passion for low-code development and the digital disruption it
            achieves motivates me to lead and train OutSystems development teams
            to deliver enterprise grade projects for clients in multiple
            domains.
          </div>

          <div className="content">
            <h5 className="red-font subtitle margin-bottom-0 margin-top-0">
              Looking for your next Tech Lead?
            </h5>
            <a
              className="button secondary trigger_sharer margin-top-20"
              href="/"
              data-rel="external"
              rel="noopener noreferrer"
            >
              Lets chat
            </a>
          </div>
        </div>
      </Section>
    );
  }
}
