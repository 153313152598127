import React, { useEffect, useRef } from "react";
import { gsap, TimelineLite } from "gsap";
import { CSSPlugin } from "gsap/CSSPlugin";

import { animateSlide } from "../animations";

// Force CSSPlugin to not get dropped during build
gsap.registerPlugin(CSSPlugin);

export const Preloader = () => {
  let preloaderTimeline = new TimelineLite();
  let preloaderWrapper = useRef(null);
  let preloaderTextOne = useRef(null);
  let preloaderTextTwo = useRef(null);
  let preloaderContent = useRef(null);

  function triggerSlideZeroAnimation() {
    animateSlide(0, true);
  }

  useEffect(() => {
    preloaderTimeline
      .from(
        [preloaderTextOne, preloaderTextTwo],
        {
          y: 400,
          ease: "cubic-bezier(0.645, 0.045, 0.355, 1)",
          duration: 0.6,
          stagger: 0.1,
        },
        1.5
      )
      .to(preloaderContent, {
        opacity: 0,
        ease: "power3",
        duration: 0.4,
        delay: 1,
      })
      .to(preloaderWrapper, {
        opacity: 0,
        ease: "power3",
        duration: 0.3,
      })
      .to(preloaderWrapper, { display: "none", duration: 0, delay: 0.5 })
      .then(triggerSlideZeroAnimation);
  });

  return (
    <div
      className="preloader-wrapper"
      ref={(element) => {
        preloaderWrapper = element;
      }}
    >
      <div
        className="preloader-content"
        ref={(element) => {
          preloaderContent = element;
        }}
      >
        <h1 className="preloader-pulse"> .</h1>
        <div>
          <h1
            className="preloader-text-lite"
            ref={(element) => {
              preloaderTextOne = element;
            }}
          >
            Driving your
          </h1>
        </div>
        <div>
          <h1
            ref={(element) => {
              preloaderTextTwo = element;
            }}
          >
            <span>
              <h1>Digital Modernisation</h1>
            </span>
          </h1>
        </div>
      </div>
    </div>
  );
};
