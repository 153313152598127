import React from "react";

import SocialMedia from "../widgets/SocialMedia";
import SocialShare from "../widgets/SocialShare";

export default class Header extends React.Component {
  render() {
    return (
      <header>
        <a className="name gsap-hidden" href="/" gsap="header-name">
          Ossama Ghanem
        </a>
        <div className="header-options gsap-hidden" gsap="header-actions">
          <SocialShare />
          <SocialMedia />
        </div>
      </header>
    );
  }
}
