import React from "react";

const Section = React.forwardRef((props, ref) => (
  <section
    className="section"
    data-anchor={props.name}
    id={props.name}
    ref={ref}>
    <div className="layout-background"></div>
    <div className="layout-wrapper">{props.children}</div>
  </section>
));

export default Section;
