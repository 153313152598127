import React from "react";
import Section from "../layout/Section";

import ProjectItem from "../ProjectItem";

import POR_Contact_Mobile from "../../assets/projects/portfolio/mobile-porfolio-contact.webp";
import POR_Contact_Mobile_PNG from "../../assets/projects/portfolio/mobile-porfolio-contact.png";
import POR_Home_Mobile from "../../assets/projects/portfolio/mobile-porfolio-home.webp";
import POR_Home_Mobile_PNG from "../../assets/projects/portfolio/mobile-porfolio-home.png";
import POR_Home_OutSystems from "../../assets/projects/portfolio/mobile-porfolio-outsystems.webp";
import POR_Home_OutSystems_PNG from "../../assets/projects/portfolio/mobile-porfolio-outsystems.png";

export default class Project_Portfolio extends React.Component {
  render() {
    return (
      <Section name="portfolio">
        <ProjectItem
          titleFirst="Port"
          titleSecond="folio."
          description="A reflection of my interests, experience and projects presented on a web page."
          year="2021"
          ShowReactIcon
          ShowNodeIcon
          ShowSassIcon
          ShowNetlifyIcon
          ShowMobileIcon
          ShowTabletIcon
          ShowDesktopIcon>
          <picture>
            <source type="image/webp" srcSet={POR_Home_Mobile} />
            <source type="image/png" srcSet={POR_Home_Mobile_PNG} />
            <img
              className="project-frame gsap-hidden gsap-reveal" gsap="project-image-one"
              src={POR_Home_Mobile_PNG}
              alt="portfolio-home"
            />
          </picture>
          <picture>
            <source type="image/webp" srcSet={POR_Home_OutSystems} />
            <source type="image/png" srcSet={POR_Home_OutSystems_PNG} />
            <img
              className="project-frame gsap-hidden gsap-reveal-reverse" gsap="project-image-two"
              src={POR_Home_OutSystems_PNG}
              alt="portfolio-outsystems"
            />
          </picture>
          <picture>
            <source type="image/webp" srcSet={POR_Contact_Mobile} />
            <source type="image/png" srcSet={POR_Contact_Mobile_PNG} />
            <img
              className="project-frame gsap-hidden gsap-reveal" gsap="project-image-three"
              src={POR_Contact_Mobile_PNG}
              alt="portfolio-contact"
            />
          </picture>
        </ProjectItem>
      </Section>
    );
  }
}
