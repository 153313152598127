import React from "react";
import Section from "../layout/Section";
import Slider from "react-slick";
import ArticleCard from "../widgets/ArticleCard";

import articleImage1 from "../../assets/articles/article-1.png";
import articleImage2 from "../../assets/articles/article-2.jfif";
import articleImage3 from "../../assets/articles/article-3.png";
import articleImage4 from "../../assets/articles/article-4.png";
import articleImage5 from "../../assets/articles/article-5.png";

export default class Articles extends React.Component {
  render() {
    var slickSettings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
      arrows: false,
      rows: 1,
      easing: "power3",
      swipeToSlide: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            rows: 1,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            rows: 1,
          },
        },
      ],
    };

    return (
      <Section name="articles">
        <div className="article-section-background"></div>
        <div className="articles-wrapper">
          <div className="article-section-heading">
            <div className="title">
              <div className="gsap-reveal" gsap="articles-title">
                Quick reads
              </div>
            </div>
            <p className="content">
              <div className="gsap-reveal" gsap="articles-content">
                Up for a quick read? Take your pick from one of these articles
              </div>
            </p>
          </div>
          <div className="article-section-body">
            <div className="article-list">
              <Slider {...slickSettings}>
                <ArticleCard
                  url="https://www.linkedin.com/pulse/how-deliver-scalable-facial-recognition-solution-aws-ossama-ghanem-1c"
                  imageURL={articleImage5}
                  OutSystemsIcon
                  year="2022"
                  hashtagOne="OutSystems"
                  hashtagTwo="CloudInnovationSummit"
                  hashtagThree="AI"
                >
                  How to Deliver a Scalable Facial Recognition Solution in
                  OutSystems & AWS
                </ArticleCard>
                <ArticleCard
                  url="https://www.linkedin.com/pulse/how-enable-store-only-updates-outsystems-ossama-ghanem"
                  imageURL={articleImage4}
                  OutSystemsIcon
                  year="2022"
                  hashtagOne="OutSystems"
                  hashtagTwo="MobileApplications"
                  hashtagThree="Distribution"
                >
                  How To: Enable Store-Only Updates in OutSystems
                </ArticleCard>
                <ArticleCard
                  url="https://www.linkedin.com/pulse/why-you-should-consider-outsystems-mobile-app-update-strategy-ghanem/"
                  imageURL={articleImage3}
                  OutSystemsIcon
                  year="2022"
                  hashtagOne="OutSystems"
                  hashtagTwo="MobileApplications"
                  hashtagThree="TechnicalPreview"
                >
                  Why You Should Consider this OutSystems Mobile App Update
                  Strategy
                </ArticleCard>
                <ArticleCard
                  url="https://www.linkedin.com/pulse/4-reasons-why-global-user-management-solution-can-elevate-ghanem/"
                  imageURL={articleImage1}
                  OutSystemsIcon
                  year="2021"
                  hashtagOne="OutSystems"
                  hashtagTwo="DigitalEcosystem"
                  hashtagThree="UserManagement"
                >
                  4 Reasons Why a Global User Management Solution Can Elevate
                  Your OutSystems Ecosystem
                </ArticleCard>

                <ArticleCard
                  url="https://www.linkedin.com/pulse/ai-what-convolutional-neural-networks-ossama-ghanem/"
                  imageURL={articleImage2}
                  year="2019"
                  hashtagOne="AI"
                >
                  AI - What are Convolutional Neural Networks?
                </ArticleCard>
              </Slider>
            </div>
          </div>
        </div>
      </Section>
    );
  }
}
